

// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME =companyName.companyName??'Company Name';
// const BASE_URL = "http://demo8.infinitemlmdemo.com/francis/backoffice/api/";
// const IMG_URL = "http://demo8.infinitemlmdemo.com/francis/uploads/images/";
// const BASE = "http://demo8.infinitemlmdemo.com/francis/";
// const API_KEY =localStorage.getItem('apiKey'); //binaryaddon;
// const DEFAULT_KEY ='12d66742-4b43-4f10-b04e-eae544f79dac'
// console.log(localStorage);
// export {
//     COMPANY_NAME,
//     BASE_URL,
//     IMG_URL,
//     API_KEY,
//     BASE,
//     DEFAULT_KEY
// }



import companyName from '../store/mobxStore/companyName';
const COMPANY_NAME =companyName.companyName??'Company Name';
const BASE_URL = "https://admin.gobeyondofficial.com/backoffice/api/";
const IMG_URL = "https://admin.gobeyondofficial.com/uploads/images/";
const BASE = "https://admin.gobeyondofficial.com/";
const API_KEY =localStorage.getItem('apiKey'); //binaryaddon;
const DEFAULT_KEY ='12d66742-4b43-4f10-b04e-eae544f79dac'
console.log(localStorage);
export {
    COMPANY_NAME,
    BASE_URL,
    IMG_URL,
    API_KEY,
    BASE,
    DEFAULT_KEY
}

