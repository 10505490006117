let currency = {
    // currentCurr : '$',
    currentCurr : '₱',
    Currecy     : [],
    status      : false,
    value       : 1,
    precision      : 2,
    id : 150
}
const changeCurrReducer = (state =  currency , action)=>{
    switch (action.type) {
        case 'CHANGE_CURR':
            return { ...state, currentCurr: action.curr,value:action.value,precision:action.precision,id : action.id};
        case 'CHANGE_CURR_STATUS':
            return { ...state, status: action.status };
        case 'ADD_CURR' :
            return {...state,Currecy : action.curr }
        default:
            return state;
    }
}

export default changeCurrReducer;