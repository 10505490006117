import React, { useCallback, useEffect, useState } from "react";
import style from "./lcp.module.scss";
import { Form } from "react-bootstrap";
import {
  DatePickers,
  FormInputControl,
  DropDownBs,
  CustomButton,
  AlertBs,
} from "../common";
import moment from "moment";
import Language from "../common/Language";
//service
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import lcpService from "../../../service/lcp/lcp";
import AppinfoService from "../../../service/common/Appinfo";
import { useParams } from "react-router";
import CrmService from "../../../service/crm/Crm";

const leadStatus = [
  {
    id: "Ongoing",
  },
  {
    id: "Rejected",
  },
  {
    id: "Accepted",
  },
];
const interest = [
  {
    id: "Very Interested",
  },
  {
    id: "Interested",
  },
  {
    id: "Not That Interested",
  },
];
const InitialForm = {
  first_name: "",
  last_name: "",
  email_id: "",
  skype_id: "",
  mobile_no: "",
  country: "",
  description: "",
  followup_date: "",
  lead_status: "Ongoing",
  status_change_date: "",
  interest_status: "Interested",

  formError: {
    first_name: "",
    last_name: "",
    email_id: "",
    skype_id: "",
    mobile_no: "",
    country: "",
    description: "",
    followup_date: "",
    lead_status: "",
    status_change_date: "",
    interest_status: "",
    id: "",
  },
};
function AddForm(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const service = new CrmService();

  const { user_name, admin } = useParams();
  const [status, setStatus] = useState(leadStatus);
  const [state, setState] = useState({
    ...InitialForm,
    alert: {
      show: false,
      message: "",
      varient: "",
    },
    editLoader: false,
    logo: "",
    country_name: "",
  });

  useEffect(() => {
    const app = new AppinfoService();
    service.getApi(admin).then((res) => {
      if (res.status) {
        console.log(res);
        localStorage.setItem("apiKey", res.data.key);
        app.Appinfo().then((res) => {
          console.log(res);
          if (res.status) {
            setState((prevState) => ({
              ...prevState,
              logo: res.data.company_info.login_logo,
            }));
          }
        });
      } else {
        alert("Invalid Admin user name");
      }
    });
  }, []);
  const getCountry = useCallback(() => {
    const commonService = new lcpService();
    commonService.getCountry().then((res) => {
      if (res.status) {
        setState((prev) => ({
          ...prev,
          countryList: res.data.country,
        }));
      } else {
        if (res.error.code === 1002) {
          history.push("/logout");
        }
      }
    });
  }, [history]);
  useEffect(() => {
    getCountry();
  }, [getCountry]);

  //get the edit data
  useEffect(() => {
    const { data, id } = props;

    setState((prev) => ({
      ...prev,
      id: id,
      ...data,
    }));
    // if(id){
    //     const addStatus = status;
    //     addStatus.push({
    //         id : 'Accepted'
    //     })
    //     setStatus(addStatus)
    // }
  }, [props, status]);

  //changeHandler
  const changeHandler = (e) => {
    // console.log("countrydata",countrydata)
    const { name, value } = e.target;
    if (name=="country") {
      let countrydata = state.countryList.find(
        (obj) => obj.value == e.target.value
      );
      setState((prev) => ({
        ...prev,
        country: value,
        country_name:countrydata.title,
        formError: {
          ...prev.formError,
          [name]: "",
        },
      }));
    } else {
      setState((prev) => ({
        ...prev,
        [name]: value,
        formError: {
          ...prev.formError,
          [name]: "",
        },
      }));
    }
  };
  //date change function
  const datechange = (date, id) => {
    setState((prev) => ({
      ...prev,
      [id]: date,
      formError: {
        ...prev.formError,
        [id]: "",
      },
    }));
  };

  //submit the
  const onSubmitForm = (e) => {
    console.log(state);
    const service = new lcpService();
    e.preventDefault();
    let isValid = true;
    let FormError = {};

    if (!state.first_name || state.first_name.length <= 0) {
      console.log("====++++");
      isValid = false;
      FormError.first_name = {
        error: "validation.required",
        field: {
          field: "firstName",
        },
      };
    }
    if (state.first_name && !state.first_name.match(/^[A-Za-z_ ]+$/)) {
      isValid = false;
      FormError.first_name = {
        error: "validation.invalid",
        field: {
          field: "firstName",
        },
      };
    }
    if (!state.last_name || state.last_name.length <= 0) {
      isValid = false;
      FormError.last_name = {
        error: "validation.required",
        field: {
          field: "lastName",
        },
      };
    }
    if (state.last_name && !state.last_name.match(/^[A-Za-z_ ]+$/)) {
      console.log("=========lastname");
      isValid = false;
      FormError.last_name = {
        error: "validation.invalid",
        field: {
          field: "lastName",
        },
      };
    }
    if (!state.mobile_no || state.mobile_no.length <= 0) {
      isValid = false;
      FormError.mobile_no = {
        error: "validation.required",
        field: {
          field: "phoneNumber",
        },
      };
    }
    if (!state.email_id || state.email_id.length <= 0) {
      isValid = false;
      FormError.email_id = {
        error: "validation.required",
        field: {
          field: "email",
        },
      };
    }

    if (state.mobile_no && !state.mobile_no.match(/^[0-9]+$/)) {
      console.log("=========lastname");
      isValid = false;
      FormError.mobile_no = {
        error: "validation.invalid",
        field: {
          field: "phoneNumber",
        },
      };
    }
    //validation in discription
    if (!state.description || state.description.length <= 0) {
      isValid = false;
      FormError.description = {
        error: "validation.required",
        field: {
          field: "description",
        },
      };
    }

    if (state.email_id) {
      var regexPattern = new RegExp(
        /^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$/
      );
      if (!regexPattern.test(state.email_id)) {
        isValid = false;
        FormError.email_id = {
          error: "validation.fieldAreNotCurrectFormat",
          field: {
            field: "email_id",
          },
        };
      }
    }
    if (isValid) {
      const today = moment();
      let FormDatas = JSON.parse(JSON.stringify(state));
      delete FormDatas.formError;
      delete FormDatas.alert;
      delete FormDatas.countryList;

      // service.addToLead(FormData)
      // let newData = {
      //   user_name: user_name,
      //   first_name: FormDatas.first_name,
      //   last_name: FormDatas.last_name,
      //   email: FormDatas.email_id,
      //   phone: FormDatas.mobile_no,
      //   skype_id: FormDatas.skype_id,
      //   comment: FormDatas.description,
      //   country: state.country_name,
      // };
      const Forms = new FormData();
      Forms.append('user_name',  user_name);
      Forms.append('first_name', FormDatas.first_name);
      Forms.append('last_name', FormDatas.last_name);
      Forms.append('email', FormDatas.email_id);
      Forms.append('phone', FormDatas.mobile_no);
      Forms.append('skype_id', FormDatas.skype_id);
      Forms.append('comment', FormDatas.description);
      Forms.append('country', state.country_name);


      service.addToLead(Forms).then((res)=>{
        if(res.status){
          setState((prev) => ({
            ...prev,
            alert: {
              show: true,
              varient: "success",
              message: "validation."+res.data,
            },
          }));

        }
        console.log(res)
      })
    } else {
      setState((prev) => ({
        ...prev,
        formError: {
          ...prev.formError,
          ...FormError,
        },
        editLoader: false,
        alert: {
          show: true,
          varient: "danger",
          message: "validation.checkValueYouHaveSubmitted",
        },
      }));
    }
  };
  //close alert
  const closeAlert = () => {
    setState((prev) => ({
      ...prev,
      alert: {
        show: false,
        message: "",
        varient: "",
      },
    }));
  };
  return (
    <div className={`${style.panel} ${style.papanel_defaultnel}`}>
      <div className={`${style.panel_body}`}>
        <div className="row">
          <div className="col-md-3">
            <div className="text-align-left">
              <Language classname="w-100" />
            </div>
          </div>
          <div className="col-md-9">
            <div className="text-align-right" style={{ textAlign: "right" }}>
              <img src={state.logo} alt="" style={{ marginTop: "-14px" }} />
            </div>
          </div>
        </div>
        {/* <div className="col-md-4">
          <div className="main_logo">
            <img
              src={"https://admin.mlm.test/uploads/images/logos/" + state.logo}
              alt=""
            />
          </div>
        </div> */}

        <div className={`${style.heading}`}>
          <h4>{t("profile.fillform")}</h4>
        </div>
        <AlertBs {...state.alert} close={closeAlert} />
        <Form onSubmit={onSubmitForm}>
          <FormInputControl
            label={t("profile.yourfirstName")}
            required={true}
            inputProps={{
              type: "text",
              name: "first_name",
              value: state.first_name,
              onChange: changeHandler,
            }}
            error={state.formError.first_name}
          />
          <FormInputControl
            label={t("profile.yourlastName")}
            required={true}
            inputProps={{
              type: "text",
              name: "last_name",
              value: state.last_name,
              onChange: changeHandler,
            }}
            error={state.formError.last_name}
          />
          <FormInputControl
            label={t("profile.youremail")}
            required={true}
            inputProps={{
              type: "text",
              name: "email_id",

              value: state.email_id,
              onChange: changeHandler,
            }}
            error={state.formError.email_id}
          />

          <FormInputControl
            label={t("profile.yourskype")}
            inputProps={{
              type: "text",
              name: "skype_id",

              value: state.skype_id,
              onChange: changeHandler,
            }}
          />
          <FormInputControl
            label={t("profile.yourmobile")}
            required={true}
            inputProps={{
              type: "text",
              name: "mobile_no",
              value: state.mobile_no,
              onChange: changeHandler,
            }}
            error={state.formError.mobile_no}
          />
          <DropDownBs
            label={t("profile.yourcountry")}
            preOption="country"
            options={{
              data: state.countryList,
              value: "value",
              label: "title",
            }}
            DropdownProps={{
              name: "country",
              onChange: changeHandler,
              value: state.country,
            }}
          />
          <Form.Group className={`${style.formGroup} ${props.formClass}`}>
            <Form.Label className={`${style.required}`} >{t("profile.yourcomments")}</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="description"
              value={state.description}
              onChange={changeHandler}

            />
             <span className={`${state.formError.description ? style.isInvalid : "d-none"}`}>{t(state.formError.description.error, { field: 'description' })}</span>
          </Form.Group>

          <CustomButton type="submit" variant="primary">
            {t(`Button.submit`)}
          </CustomButton>
        </Form>
      </div>
    </div>
  );
}
export default AddForm;
