import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import style from './tools.module.scss';
import { useTranslation } from 'react-i18next';
import { EmailInvites, BannerInvites, TextInvite, SocialInvite } from './promotionalTools';
import { AlertBs } from '../common';
import { IMG_URL } from "../../../configuration/apiconfig"
import { DriveEtaTwoTone } from '@mui/icons-material';

const PromotionalToolsContent = (props) => {
    const { t } = useTranslation();
    return (
        console.log("These are the props in promotional tools content ", props),
        <div className={style.inviteContainer}>
            <AlertBs
                {...props.alert}
            />
            <Tabs defaultActiveKey="email" id="tab">
                <Tab eventKey="email" title={t('profile.email')}>
                    {
                        props.social_emails !== null ?
                            <EmailInvites
                                social_emails={props.social_emails}
                                inviteHistory={props.inviteHistory}
                            />
                            :

                            <div
                                className={`${style.NodataImage}`}
                                style={{ textAlign: "center" }}
                            >
                                <img
                                    src={IMG_URL + "logos/no-datas-found.png"}
                                    alt="nodata"
                                    style={{ width: "50%" }}
                                />
                            </div>
                    }


                </Tab>

                <Tab eventKey="banner_invites" title={t('Common.bannerInvite')}>
                    {props.banners !== null ?
                        <BannerInvites
                            banners={props.banners}
                        />
                        :
                        <div
                            className={`${style.NodataImage}`}
                            style={{ textAlign: "center" }}
                        >
                            <img
                                src={IMG_URL + "logos/no-datas-found.png"}
                                alt="nodata"
                                style={{ width: "50%" }}
                            />
                        </div>
                    }

                </Tab>
                <Tab eventKey="textInvite" title={t('Common.textInvite')}>
                    {
                        props.InviteText !== null ?
                            <TextInvite
                                inviteText={props.InviteText}
                            />

                            :

                            <div
                                className={`${style.NodataImage}`}
                                style={{ textAlign: "center" }}
                            >
                                <img
                                    src={IMG_URL + "logos/no-datas-found.png"}
                                    alt="nodata"
                                    style={{ width: "50%" }}
                                />
                            </div>

                    }

                </Tab>
                <Tab eventKey="socialInvite" title={t('Common.socialInvite')}>
                    {
                        props.socialInvite !== null ?
                         <SocialInvite
                         data={props.socialInvite}
                            />
                        :
                        <div
                        className={`${style.NodataImage}`}
                        style={{ textAlign: "center" }}
                    >
                        <img
                            src={IMG_URL + "logos/no-datas-found.png"}
                            alt="nodata"
                            style={{ width: "50%" }}
                        />
                    </div>
                    }
                   
                </Tab>
            </Tabs>
        </div>
    );
}
export default PromotionalToolsContent;