import React from 'react';
import style from './Common.module.scss';
import { ListGroup,Button} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
const ToolTips = (props) => {
    const {t} = useTranslation();
    const TooltipConfig  = (item,index) =>{
        if (props.config[item]) {
            if (item === 'join_date') {
                return <ListGroup.Item key={index} className={style.tooltipListItem}>
                            <div>{t(`Common.${item}`)}</div>
                            <div>:&nbsp;&nbsp;&nbsp;{moment(props.join_date).format('MMM Do YY') }</div>
                    </ListGroup.Item>
            }else if(item==='sponsor_status'){
                return <ListGroup.Item className={style.tooltipListItem} key={index}>
                        <div>{t(`Common.sponsor`)}</div>
                        <div>:&nbsp;&nbsp;&nbsp;{props.sponsor_name?props.sponsor_name:'NA'}</div>
                    </ListGroup.Item>
            }else if(item==='gpv'){
                return <ListGroup.Item className={style.tooltipListItem} key={index}>
                        <div>{t(`Common.groupPV`)}</div>
                        <div>:&nbsp;&nbsp;&nbsp;{props.group_pv?props.group_pv:'0'}</div>
                    </ListGroup.Item>
            }else if(item!=='rank_status' &&item!=='first_name'&&props[item]){
                return <ListGroup.Item className={style.tooltipListItem} key={index}>
                        <div>{t(`Common.${item}`)}</div>
                        <div>:&nbsp;&nbsp;&nbsp;{props[item]}</div>
                    </ListGroup.Item>
            }
        }
    }
    return ( 
        <>
            <div className={style.tooltipHeader}>
            {props.image?
                <img src={props.image} alt="" />:
                <img src={props.photo} alt="" />}

                <p>{props.user_name}</p>
                {
                    (props.config &&props.config.first_name==='yes')&&
                        <p>{`${props.first_name} ${" "} ${props.last_name}`}</p>
                }
            </div>
            <div className={style.content}>
                <ListGroup className={style.tooltipList}>
                    {   
        
                        Object.keys(props.config).map((item,index)=>{
                            if(props.config[item] === 'yes'){
                                return TooltipConfig(item,index)
                            }
                            return false
                        })

                    }
                    {
                        props.config.rank_status==='yes' &&
                        <div>
                            <Button style={{background : props.rank_color??'#7266ba',borderColor :'transparent'}}>{props.rank_name??'NA'}</Button>
                        </div>
                    }
                </ListGroup>
            </div>
        </>
     );
}
 
export default ToolTips;