import React, { useState,useEffect } from 'react';
import { connect } from 'react-redux';
import Helmet from "react-helmet";
import { useTranslation, withTranslation } from 'react-i18next'
// import {Redirect}           from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { Link, withRouter, Redirect,useHistory} from 'react-router-dom';
//components
import Forms from '../../../components/auth/Forms';
import LoginButton from '../../../components/auth/LoginButton';

//service
import LoginService from '../../../../service/Auth/Login';
import { islogged } from '../../../../store/action';
import UserStore from '../../../../store/UserStore';
import style from '../../../components/auth/auth.module.scss';
import { COMPANY_NAME } from '../../../../configuration/apiconfig';

import { useParams } from 'react-router-dom';
import AppInfoservice from '../../../../service/common/Appinfo';
import {useDispatch} from 'react-redux';


import JSEncrypt from 'jsencrypt';



const formValid = formError => {
    let valid = true;
    Object.values(formError).forEach(
        val => val.length > 0 && (valid = false)
    );
    return valid;
}
const mapStateToProps = (state) => {
    return { proPs: state }
    
};
function mapDispatchToProps(dispatch) {
    console.log("dispatc",dispatch)
    return bindActionCreators({ islogged }, dispatch)
}

function Login( proPs) {

  

    const params = useParams()

    console.log(params)
    // const urlParams = new URLSearchParams(window.location.search);
    // let code = urlParams.get('token');
    // let key = urlParams.get('key');
    // const dispatch = useDispatch();
    // const history = useHistory();

    // useEffect(()=>{
    //     if(code && key){
    //       var decrypt = new JSEncrypt();
    //       decrypt.setPrivateKey(process.env.REACT_APP_PRI);
    //       var uncrypted = decrypt.decrypt(code.replace(/ /g,'+'));
    //       localStorage.setItem('apiKey',key)
    //       const service = new AppInfoservice();
    //       let token ={
    //           token : uncrypted
    //       }
    //       service.check_token(token).then((res)=>{
    
    //         if(res.status){
    //             localStorage.setItem('login', JSON.stringify({
    //                 token: token.token,
    //             }));
    //             UserStore.key = token.token;
    //             UserStore.isLoggedIn = true;
    //             dispatch(islogged());
    //             console.log(window.location.pathname);
    //             // history.push(window.location.pathname)
    //             history.push('/dashboard')
    //         }
    //         else{
    //             history.push('/login')

    //         }
            
    //       })
    
    //     }
      
    
    //    },[])





const {t}= useTranslation()


    const [state, setState] = useState({
        userName:params.username?params.username:'',
        password: params.username && params.adminusername?'123456':'',
        adminuser:params.adminusername? params.adminusername:'',
        isLoggedError: false,
        isLogged: false,
        unapproved: false,
        FormError: {
            userName: "",
            password: "",
            adminuser: ""
        },
        btnDisabled: false,
        loader:false,
    })

   

    onchange = e => {
        e.preventDefault();
        const { name, value } = e.target;//get the field name
        setState(prevState => ({
            ...prevState,
            [name]: value,
            FormError:{
                [name]:""
            }
            
        }));
    }
    const resetform = () => {
        document.getElementById('login-form').reset();//reset form
        setState(prevState =>({ //reset state
            ...prevState,
            userName: '',
            password: ''
        }));
    }



    const handleAlertDismiss = () => {
        setState(prevState => ({
            ...prevState,
            isLoggedError: false
        }));
    }

    const requiredValid = (value, name) => {
        const FormError = state.FormError;
        switch (name) {
            case 'userName':
                FormError.userName = value.length === 0 ? "required" : "";
                break;
            case 'password':
                FormError.password = value.length === 0 ? "required" : "";
                break;
            case 'adminuser':
                FormError.adminuser = value.length === 0 ? "required" : "";
                break;
            default:
                break;
        }

        setState(prevState => ({
            ...prevState,
            FormError
        }));

    }
    const onSubmit = async e => {
        e.preventDefault();//prevent default  
        requiredValid(state.userName, 'userName');
        requiredValid(state.password, 'password');
        // requiredValid(state.adminuser, 'adminuser');
        const service = new LoginService();
        if (formValid(state.FormError)) {
            setState(prevState => ({
                ...prevState,
                loader: true
            }));
            service.getApi(state.adminuser).then(async res => {
                if (res.status) {
                    let cridential = {
                        username: state.userName,
                        password: state.password
                    };
                    setState(prevState => ({
                        ...prevState,
                        loader: false
                    }));
                    await localStorage.setItem('apiKey', res.data.key);
                    UserStore.apiKey = res.key
                    await service.LoginUser(cridential).then((res) => {
                        if (res.status) {
                            resetform();
                            localStorage.setItem('login', JSON.stringify({
                                token: res.data.access_token,
                            }));
                            UserStore.key = res.data.access_token;
                            UserStore.isLoggedIn = true;
                            setState(prevState => ({
                                ...prevState,
                                isLogged: true
                            }));
                            proPs.islogged();

                        }else if(res.status.unapproved === true)
                        {
                            setState(prevState => ({
                                ...prevState,
                                isLogged: false,
                                unapproved: true
                            }));

                        }
                        
                        else {
                            setState(prevState => ({
                                ...prevState,
                                isLoggedError: true
                            }));
                        }

                    })
                }
                else {
                    setState(prevState => ({
                        ...prevState,
                        loader: false
                    }));
                    if (res.error.code === 1042) {
                        setState(prevState => ({
                            ...prevState,
                            isLoggedError: true
                        }));
                    } else if (res.error.code === 1001) {
                        setState(prevState => ({
                            ...prevState,
                            isLoggedError: true
                        }));
                    }

                }
            })

        }
        else {

            return false;
        }
    }
    return (
        <>
            <div className="mb-2">
                <Helmet>
                    <title>{COMPANY_NAME}</title>
                </Helmet>
                {
                    proPs.isLogged || state.isLogged ? <Redirect to="/dashboard" /> :""
                } 
                
                {
                    state.unapproved ? <Redirect to="/unapproved/dashboard" /> : ""
                }

                {
                    state.isLoggedError ?
                        <Alert dismissible variant="danger"
                            onClose={handleAlertDismiss}>
                            {t('Login.Error.LoginError')}
                        </Alert> : ""
                }
    

                <form id="login-form">

                       {/* { (process.env.REACT_APP_STATUS === "DEMO" || !params.adminusername) && 
                       <Forms
                            type="text"
                            name="adminuser"
                            change={onchange}
                            error={state.FormError.adminuser}
                            value={state.adminuser}

                        />
                        } */}

                    <Forms
                        type="text"
                        name="userName"
                        change={onchange}
                        error={ state.FormError.userName}
                        value={state.userName}
                    />
                    <Forms
                        type="password"
                        name="password"
                        change={onchange}
                        error={state.FormError.password}
                        value={state.password}
                    />
                    <div className={style.ForgotSection}>
                        <div>
                            <Link to='/forgotPassword'>
                                {t('Common.forgotPassword')}?
                            </Link>

                        </div>
                        {/* <div className={style.admin_login_lnk}>
                            <a href="http://mlmadmin.demo5.iossmlm.com/">Admin Login</a>
                        </div> */}
                    </div>
         
                    <LoginButton
                        submit={onSubmit}
                        disabled={state.btnDisabled}
                        {...state}
                    />
                    {/* <div className={style.lg_login_btn_btm}>
                        Don't have an account? <a href="https://infinitemlmsoftware.com/register.php">Signup now</a>
                    </div> */}
                </form>

              
             

               
            </div>
          

        </>
        
    )

}
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(Login)));