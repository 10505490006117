import React,{useState,useEffect} from 'react';
import {Form} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import { usePopperTooltip } from 'react-popper-tooltip';
import { animated, useTransition } from 'react-spring';
import DatePickers from '../common/Datepickers'
import 'react-popper-tooltip/dist/styles.css';
import style from './registration.module.scss';

function FormsControl({policy,validation,...props}) {
    const {t} = useTranslation();    
    const [controlledVisible, setControlledVisible] = useState(false);
    const {
        getArrowProps,
        getTooltipProps,
        setTooltipRef,
        setTriggerRef,
      } = usePopperTooltip({
        visible: controlledVisible,
        onVisibleChange: setControlledVisible,
        trigger: 'focus'
      });
      const transitions = useTransition(controlledVisible, null, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
      });


    const Components = type =>{
        if(type === 'text' || type === 'password' || type === 'number'){
            return <Form.Control 
                    ref={(props.data.code==='password'&& props.data.validation.disableHelper===0)?setTriggerRef:null}
                    id={props.data.code}
                    type={type} 
                    name={props.data.code}
                    value={props.data.value??''}
                    disabled={!props.data.isEditable} 
                    className={`
                        ${style.inputField}
                        ${props.data.error ?`${style.formsHasError}form-control is-invalid`: ""}
                        `} 
                    onChange={e=>props.change(e,props.data.required)}
                    onBlur={()=>{
                        if(props.data.code === 'sponsorUserName'||props.data.code === 'password' ||props.data.code==='userName'){
                            props.blurhandler(props.data.code)
                        }
                    }}
                />
        }else if(type === 'tel'){
            return <Form.Control 
            ref={(props.data.code==='password'&& props.data.validation.disableHelper===0)?setTriggerRef: null}
            id={props.data.code}
            type={type} 
            pattern= {"[0-9]{9,15}"}
            placeholder = {`Enter your mobile number`}
            name={props.data.code}
            value={props.data.value??''}
            disabled={!props.data.isEditable} 
            className={`
                ${style.inputField}
                ${props.data.error ?`${style.formsHasError}form-control is-invalid`: ""}
                `} 
            onChange={e=>props.change(e,props.data.required)}
            onBlur={()=>{
                if(props.data.code === 'sponsorUserName'||props.data.code === 'password' ||props.data.code==='userName'){
                    props.blurhandler(props.data.code)
                }
            }}
        />
        }else if(type === 'select'){
            return <Form.Control 
                        as="select"
                        disabled={props.status||props.data.disabled}
                        className={`${style.inputField}`}
                        name={props.data.code}
                        onChange={e=>props.change(e,props.data.required)}
                        // onChange={ (e)=>{props.dropdownChange(e.target.value,props.data)}}
                        value={props.data.value ?? ''}
                        >
                            <option  value=''>{t('registration.selectOption',{field:props.data.code})}</option>
                        {
                            props.data.options.map((option,index)=>{
                                return <option key={index} value={option.value}>{t(option.code)}</option>
                            })
                        }
                        
                    </Form.Control>
        }else if(type === 'checkbox'){
            return <div className={style.checkbox}>
                <input
                type="checkbox"
                name={props.data.code}
                className={style.checkboxInput}
                onChange={e=>props.change(e,props.data.required)}
                value={props.data.value}
                defaultChecked={props.data.value}
                // ref={el => (this.selector = el)}
                id='terms'
                // {...inputProps}
                />
                <label className={`${style.checkboxLabel} ${props.data.required && style.required}  ${props.data.error && style.isInvalid}`} onClick={()=>props.openModal(props.data.content)}>{t('Common.acceptTermsAndCondietion')}</label>
            </div>
        }else if(type === 'date'){
            return <DatePickers 
                    disabled={false}
                    start={props.data.value}
                    datechange={props.selectDate}
                    id={props.data.code}
            />
        }
    }
    return (
        <Form.Group >
            {
                props.data.code!=='agree_terms'&&
                <Form.Label htmlFor={props.data.code} className={`${props.data.required && style.required} ${props.data.error && style.isInvalid}`}>
                    {t('profile.'+props.data.code)}
                </Form.Label>
            }
                {Components(props.data.type)}
                {
                    (props.data.code === 'password'&&props.data.validation.disableHelper===0)&&
                    transitions.map(({ item, key, props }) =>
                        item && (
                            <animated.div
                                key={key}
                                ref={setTooltipRef}
                                {...getTooltipProps({
                                    className: 'tooltip-container',
                                    style: props,
                                })}
                                >
                                    <div>
                                        {
                                            Object.keys(validation).map(function(key, index) {
                                                if(key !== 'disableHelper'){
                                                    return <p key={index}>
                                                                {
                                                                policy[key]?
                                                                    <i className="fa fa-check-circle" style={{color:"green"}}></i> :
                                                                    <i className="fa fa-times-circle" style={{color:"#f05050"}}></i> 
                                                                }
                                                                {t('validation.atleast_'+key,{count:validation[key]})}
                                                            </p>
                                                }
                                                return false
                                              })
                                        }
                                    </div>
                                    <div {...getArrowProps({ className: 'tooltip-arrow' })} />
                            </animated.div>
                    )
                    )
                }
            
            {/* error message */}
            <Form.Text className={`
                ${props.data.error
                    ? style.isInvalid
                    : "d-none"}
                `}>
                    {t(props.data.error,props.data.errorField)}
            </Form.Text>
        </Form.Group>
    )
}

export default FormsControl
