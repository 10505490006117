import React from 'react';
import style from './package.module.scss';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
function UpgradePackage(props) {
  const {t} = useTranslation();
  const currency = useSelector(state=>state.curr);
  return (
                  <div className="upgrade-updateDetails">
                    <h3>{t('Common.packageToUpgrade')}</h3>
                    <div className={style.upgradeDetails}>
                      <div className="upgradeDetailsContainer">
                        <div>
                          <h4>{t('Common.packageName')}</h4>
                          <p id="package_name2">{props.data.product_name}</p>
                        </div>
                        <div>
                          <h4>{t('Common.price')}</h4>
                          <p id="package_price2">{currency.currentCurr} {(props.data.price* currency.value).toFixed(currency.precision)}</p>
                        </div>
                        <div>
                          <h4>{t('Common.PV')}</h4>
                          <p id="package_pv2">{props.data.pair_value}</p>
                        </div>
                      </div>
                    </div>

                    <div className={style.amount}>
                        {t('Common.amountToPay')} &nbsp;
                        <span id="amount_to_pay2">  
                          {/* {currency.currentCurr} {((props.data.price-props.current_package_price)*currency.value).toFixed(currency.precision)} */}
                          {currency.currentCurr} {((props.data.price)*currency.value).toFixed(currency.precision)}
                          </span>
                    </div>
                    
              </div>
  )
}

export default UpgradePackage