import React from 'react';
import {Row,Col,Card, Form} from 'react-bootstrap';
import style from './package.module.scss';
import {useTranslation} from 'react-i18next';
import UpgradePackageList from '../package/UpgradePackageList';
import UpgradePackage from '../package/UpgradePackage';
import PaymentMethods from '../common/PaymentMethods';
import AlertBs from '../common/AlertBs';
import {useSelector} from 'react-redux';

function UpgradeContent(props) {
    const {t} = useTranslation();
    const currency = useSelector(state=>state.curr);

    const styles = {
        marginTop: "32px",
        color: "red"
    };

    return (
        <div className={style.MainContainer}>
            <Row>
                <Col>
                    <AlertBs 
                    {...props.alert}
                    close={props.closeAlert}
                    />
                    <Card className={style.ManiContent}>
                        <Card.Body className={style.ContentBody}>
                        <table>
                            <tbody>
                                    <tr>
                                        <td>{t('Common.package')} &nbsp;&nbsp;</td>
                                        <td>: &nbsp;&nbsp;</td>
                                        <td>{props.data.product_name}</td>
                                    </tr>
                                    
                                    <tr>
                                        <td>{t('Common.price')} </td>
                                        <td>:</td>
                                        <td>{currency.currentCurr} {(currency.value*props.data.price).toFixed(currency.precision) } </td>
                                    </tr>
                                    
                                    <tr>
                                        <td>{t('Common.PV')}</td>
                                        <td>:</td>
                                        <td>{props.data.pv}</td>
                                    </tr>

                            </tbody>
                        </table>


                { (props.pending_approval === false ) ?
                        <div>
                        <UpgradePackageList 
                                upgrade_list={props.upgrade_list}
                                selectPackage={props.selectPackage}
                        />
                        { props.selected_package && 
                            <Row>
                                <div className={style.tabSection} id="upgrade_pack_div2">
                                    <div className={style.tabContainer}>
                                        <UpgradePackage 
                                            data={props.selected_package}
                                            current_package_price={props.data.price}
                                            />
                                        <Form onSubmit={props.onSumbit}>
                                            <PaymentMethods 
                                                methods={props.paymentData}
                                                selectedTab={0} 
                                                product={props.selected_package.product_id}
                                                payment_type="package_upgrade"
                                                paymentMethodChange={props.tabChange}
                                                amount={parseInt(props.data.price).toFixed(2)}
                                                {...props}
                                            />
                                        </Form>
                                    </div>
                                </div>
                            </Row>
                        }

                        </div>

                        :
                        <div style ={styles}>
                           {t('Common.pendingapproval')}
                        </div>

                    }



                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    
    )
}

export default UpgradeContent
